@media print {
  @page {
    size: A4 portrait;
  }
  .coverPage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0 20rem 0;
    height: 100vh;
    font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400;
  }

  .coverPage span {
    font-size: large;
  }

  .logo {
    align-self: flex-end;
    width: 35%;
  }

  .graphicSection {
    text-align: center;
  }

  .graphicSection span {
    display: block;
    font-size: xx-large;
  }

  .graphicSection img {
    width: 50%;
    margin-bottom: 3rem;
  }

  .MuiPaper-root.MuiPaper-elevation1 {
    margin-bottom: 5rem;
    border-color: unset;
    background-color: unset;
    page-break-inside: avoid;
  }

  .MuiPaper-root.MuiPaper-elevation1:last-child {
    margin-bottom: 0em;
  }

  .MuiGrid-root.MuiGrid-container {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .MuiButtonBase-root.MuiListItem-root.MuiListItem-button div {
    flex-basis: 75%;
  }
  .pageHeader {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    padding-bottom: 7rem;
    font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    page-break-before: always;
  }

  .left {
    flex-basis: 80%;
    align-self: flex-end;
    font-size: x-large;
  }

  .right {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .right img {
    padding-bottom: 1.4rem;
  }

  .aggregateTable {
    flex-basis: 70%;
  }

  .aggregateChart canvas {
    width: 200px !important;
    margin-top: 28px !important;
  }

  .aggregateChart a svg {
    width: 700px !important;
  }

  .heading {
    color: red;
  }

  .widgetTitle {
    font-size: 150%;
    margin: 0;
  }

  .widget {
    margin-top: 2rem;
  }

  .pdfMap {
    display: flex;
    flex-direction: column-reverse;
  }
  .pdfMap:first-child {
    flex-basis: 20% !important;
  }

  .flag svg {
    width: "400px";
  }

  .pdfGeography {
    stroke-width: 0;
  }
}
